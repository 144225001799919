section.login-section {
    overflow: hidden;
    padding: 20px;
    height: max-content;
}

.right-login-img img {
    width: 100%;
    height: 100%;
}

.lgn-frm {
    max-width: 60%;
    margin: 30px auto 0 auto;
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    border-top: 2px solid #c8d4df;
}

h2.login-heading {
    font-size: 40px;
    font-weight: 600;
}

.login-section p {
    margin-bottom: 6px;
}

input {
    border: 1px solid #c8d4df;
    width: 100%;
    padding: 8px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.cstm-logo {
    padding-bottom: 15px;
}

button {
    color: #dce2ff;
    width: 100%;
    border: none;
    padding: 10px;
    background: #526eff;
    border-radius: 10px !important;
    font-weight: 500;
}

label {
    margin-bottom: 4px;
    font-weight: 600;
}

input[type='checkbox'] {
    width: 21px !important;
    padding: 0;
}

.checkbox span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.checkbox>input:checked+span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 2px;
    top: -4px;
    font-size: 20px;
    font-weight: 400;
}

.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.prTabs button {
    border-radius: 6px 6px 0 0 !important;
}

/* --------------------------------------------------- */

.login-left {
    background: url(../../../assets/login-left.png);
    padding: 70px 50px 112px 50px;
    background-size: cover;
    background-position: top;
    border-radius: 30px 0 0px 30px !important;
}

.lgn-left {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-details-all {
    width: 100%;
    margin: 0 auto;
}

section.login-section {
    overflow: hidden;
    padding: 38px 32px 38px 38px;
    height: max-content;
}

.right-login-img img {
    width: 100%;
}

.right-login-img {
    padding-left: 30px;
    height: 100%;
}

.lgn-frm {
    max-width: 55%;
    margin: 30px auto 0 auto;
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    border-top: 2px solid #c8d4df;
}

h2.login-heading {
    font-size: 40px;
    font-weight: 600;
    padding-bottom: 10px;
}

.login-section .login-rw input {
    box-shadow: none !important;
}

.login-section .checkbox span {
    margin-left: 10px;
    font-size: 15px;
}

.login-section .login-rw input {
    box-shadow: none !important;
    font-size: 16px;
}


input {
    border: 1px solid #a3bed9;
    width: 100%;
    padding: 8px 10px;
    border-radius: 4px;
    margin-bottom: 14px;
}

.pass-remember input {
    width: auto;
}

.pass-remember {
    padding-bottom: 10px;
    padding-top: 5px;
}

label {
    margin-bottom: 8px;
    font-weight: 600;
}

.checkbox>input {
    height: 21px;
    width: 21px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 4px;
    border: 2px solid rgba(82, 110, 255, 0.24);
    transition-duration: 0.3s;
    background-color: rgba(82, 110, 255, 0.12);
    cursor: pointer;
}

.checkbox span {
    margin-left: 10px;
    font-size: 14px;
}

.checkbox>input:checked+span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 4px;
    top: -1px;
    font-size: 17px;
    font-weight: 300;
}

.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

@media (min-width: 1900px) {
    .right-login-img img {
        border-radius: 0 30px 30px 0;
        width: 100% !important;
        height: 91vh !important;
        padding-left: 0 !important;
        margin: 0 auto;
        object-fit: cover;
    }

    .cstm-logo {
        padding-bottom: 30px;
    }

    table tbody tr td,
    table thead th {
        font-size: 16px !important;
    }

    table tbody tr,
    table thead tr {
        border-radius: 6px !important;
    }

    .login-left {
        height: 91vh !important;
        padding: 50px;
    }

    .lgn-left {
        width: 100% !important;
        justify-content: flex-start;
        align-items: center;
        padding-top: 30px;
    }

    .right-login-img {
        padding-left: 24px !important;
    }

    .lgn-frm {
        max-width: 47% !important;
        width: 100%;
        padding: 30px !important;
    }

    .login-section p {
        margin-bottom: 10px;
    }

    .pass-remember {
        padding-bottom: 18px !important;
        padding-top: 10px !important;
    }

    h2.login-heading {
        font-size: 40px;
        padding-bottom: 20px !important;
    }

    .right-login-img {
        text-align: center !important;
    }

    .right-login-img {
        text-align: center !important;
    }
}