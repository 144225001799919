span.new-order {
    border: 1px solid #dfe6ec;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 30px;
    color: rgba(17, 17, 17, 0.48);
}

.arrow-img {
    margin: 0 4px;
}

.location_list {
    width: 100%;
    max-height: 40vh;
    padding: 4px;
    margin: 0 auto;
    border: 1px solid #A3BED9;
    overflow: auto;
    margin-bottom: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 16px 20px rgba(0, 0, 0, 0.06);
}

.new-ordr {
    flex-flow: row wrap;
}

span.new-order-dark {
    background: #c0f2ec;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 30px;
}

span.new-order.order.order_status-completed {
    color: #000;
}

.orders {
    height: fit-content;
    border-radius: 50px;
    padding: 4px 10px 4px 10px;
    text-transform: capitalize;
}

.order_status-packing {
    background-color: #c0f2ec;
}

.order_status-received {
    background-color: #ffe0c2;
}

.order_status-new {
    background-color: #c4edc6;
}

.order_status-assigned {
    background-color: #faedbf;
}

.order_status-completed {
    background-color: #cfe2fe;
}

.order_status-dispatched {
    background-color: #c0f2ec;
}

.order_status-cancelled {
    background-color: #dfe5ec;
    color: #1111117a;
}

.order_status-delivered {
    background-color: lightgreen;
    color: #000;
}

/* Orders Css */

.users-table-head.orders .input-group.search {
    width: max-content;
}

.modal-body.create-user {
    padding-bottom: 0 !important;
}

.modal-body.show-user {
    padding: 0 !important;
}

p.green-bg {
    background: #c4edc6;
    margin: 0;
    border-radius: 30px;
    padding: 2px 5px;
    text-align: center;
}

.order-left-head .form-check {
    padding-right: 15px;
}

.order-left-head {
    display: flex;
    align-items: center;
    height: 100%;
}

ul.order-listing {
    text-align: center;
}

a.order-creat {
    text-decoration: none;
}

.coupon-card .user-details-list li {
    font-size: 14px;
}

.coupon-card ul li strong {
    width: 40%;
}

.rewards-right .form-check {
    margin-right: 11px;
}

.collect-rewards {
    display: flex;
    justify-content: space-between;
}

.modal.new-order .modal-dialog {
    max-width: 82%;
}

.order-col-rgt {
    padding-left: 20px !important;
}

.new-order-leftcol {
    padding-right: 20px !important;
    border-right: 1px solid #c8d4df;
}

.order-details {
    padding-left: 10px;
    font-size: 15px;
}

.f-14 {
    font-size: 14px;
}

.new-order-leftcol .accordion-item {
    border-bottom: 1px solid #c8d4df !important;
}

.order-new-search {
    border-bottom: 1px solid #c8d4df;
    padding-bottom: 20px;
}

h5.card-title {
    font-size: 18px;
    margin: 0;
}

.order-list-tabs .nav-pills .nav-link {
    border: 1px solid #dfe6ec;
    color: #000;
    font-size: 15px;
    font-weight: 400;
}

.form-check-input[type=radio] {
    padding: 0 !important;
}

.order-list-tabs .card {
    margin-bottom: 20px;
}

.order-list-tabs li.nav-item {
    margin-right: 10px;
    margin-bottom: 10px;
}

.order-list-tabs .nav-pills .nav-link.active {
    background: #526eff !important;
    color: #fff;
}

p.card-text {
    font-size: 14px;
    margin: 0;
}

.order-list-tabs {
    padding: 30px 0;
}

p.order-flow {
    margin-right: 30px;
}

.pr-icon {
    border: 1px solid #c8d4df;
    width: max-content;
    margin: 10px auto;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.corps-list button {
    border: none !important;
    box-shadow: none !important;
}

.new-products {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.jacob-info {
    display: flex;
    flex-flow: row wrap;
}

.jacob-info ul {
    width: 50%;
    font-size: 15px;
}

.new-products-list {
    border-bottom: 1px solid #c8d4df;
}

.border-btm {
    padding-bottom: 10px;
    border-bottom: 1px solid #c8d4df;
}

ul.instruction-listing {
    list-style: none;
    padding-left: 0;
    font-size: 15px;
    border-top: 1px solid #c8d4df;
    padding-top: 15px;
}

ul.instruction-listing li {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    border-bottom: 1px dashed #c8d4df;
}

hr {
    color: #c8d4df;
    opacity: 1;
}

.instuct-btn button.btn {
    width: 100%;
}

.product-check-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #c8d4df;
    border-radius: 6px;
    padding: 6px 10px;
    margin-bottom: 15px;
}

.dark-tx {
    color: #526eff;
}

.product-check-box p {
    margin: 0;
}

.sm-tx {
    font-size: 13px;
}

.text-right {
    text-align: right;
}

.product-check-box img {
    margin-right: 10px;
}

.choose-back {
    padding: 10px 0;
    margin-bottom: 15px;
    border-top: 1px solid #c8d4df;
    border-bottom: 1px solid #c8d4df;
}

.choose-cookie {
    align-items: center;
    padding-bottom: 20px;
}

.back-bt {
    width: 34px;
    height: 34px;
    border: 2px solid #526eff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

button.btn.add-order {
    border: 2px solid #526eff;
    width: max-content;
    font-size: 18px;
    padding: 5px 12px !important;
    margin: 0 10px;
}

.f-18 {
    font-size: 18px;
}

.mail-box {
    background: #f5f7f9;
    border: 1px solid #dfe6ec;
    padding: 8px 20px;
    width: max-content;
    margin: 15px auto 20px auto;
    display: flex;
    align-items: center;
    border-radius: 6px;
}

ul.prodct-listing.invoice li {
    justify-content: space-between;
    padding: 8px 0;
    display: flex;
}

.text-left {
    text-align: left;
}

ul.prodct-listing.invoice li svg {
    margin-right: 8px;
}

span.light-bg {
    background: #c0f2ec;
    padding: 5px 10px;
    border-radius: 30px;
}

ul.prodct-listing.invoice li strong {
    display: flex;
}

span.or-bg {
    background: #ffe0c2;
    padding: 5px 10px;
    border-radius: 30px;
}

span.new-order {
    border: 1px solid #dfe6ec;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 30px;
}

.arrow-img {
    margin: 0 4px;
}

span.new-order-dark {
    background: #c0f2ec;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 30px;
}

.jacob-info ul.corporation-list li strong {
    width: 30%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #111111;
}

.jacob-info ul.corporation-list li span {
    font-size: 16px;
}

.jacob-info ul.corporation-list li:first-child strong {
    width: max-content !important;
}

.jacob-info ul.corporation-list li strong svg,
.jacob-info ul.corporation-list li strong img {
    margin-right: 10px;
}

.new-ordr .ordr-icn img {
    height: 80px;
    width: 80px !important;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 5px;
}

.order-details p strong {
    font-weight: 500;
    font-size: 14px;
}

.new-products-list>div {
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 14px;
}

p.instructions {
    color: rgba(17, 17, 17, 0.64);
    font-size: 13px;
    padding: 10px 0px;
}

p.instructions strong {
    color: #111111;
    font-weight: 500;
}

.order-details p {
    line-height: 18px;
}

.order-details {
    width: 60%;
}

.make-order input[type='checkbox'] {
    margin-bottom: 0 !important;
}

button:focus,
input:focus,
select:focus {
    outline: none !important;
    box-shadow: none !important;
}

.showuser-list:empty {
    border: none !important;
    box-shadow: none !important;
}

.make-search input {
    margin: 0 !important;
}

.new-products-list {
    padding: 15px 0;
}

.order-list-tabs .card-body {
    border-top: 1px solid #dfdfdf;
    position: relative;
}

.order-list-tabs p.card-text.price {
    position: absolute;
    top: -29px;
    width: max-content;
    left: 0;
    color: #526EFF;
    background: #F5F7F9;
    padding: 3px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 0 12px 0 0;
}

.timer-row {
    padding: 15px 0;
}

.accordion-button:not(.collapsed) {
    background-color: transparent !important;
}

.assign-drive>div {
    display: flex;
    flex-flow: row wrap;
    padding: 4px 0;
}

p.cookie-flx {
    font-size: 14px;
}

ul.order-listing.order-listdate p.cookie-flx {
    flex-flow: row wrap;
}

ul.order-listing.order-listdate p.cookie-flx .complete-order {
    width: 33%;
    text-align: left;
}

ul.order-listing.order-listdate p.cookie-flx span {
    width: 33%;
    text-align: left;
}

ul.order-listing li {
    padding: 6px 0;
}

.assign-drive>div strong {
    width: 34%;
    font-weight: 600;
    color: #111111;
    font-size: 16px;
}

p.instructions svg {
    height: 18px;
}

.assign-drive>div span {
    color: rgba(17, 17, 17, 0.8);
    font-size: 16px;
    font-weight: 400;
}

.info-header.location-edit .right-info strong {
    font-weight: 600;
}

.accordion-item span.orders {
    font-size: 13px;
}

.instruction-listing li span {
    color: rgba(17, 17, 17, 0.64);
    font-weight: 500;
    font-size: 14px;
}

p.cookie-flx span {
    font-weight: 500;
}

.order-listing.refund {
    margin: 0 auto;
    max-width: 70%;
}

.order-listing.refund li {
    display: flex;
    align-items: center;
}

select.form-control {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background: transparent;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB/SURBVHgBdY9BDkAwEEX/FHtH4AYsxcpNOJkjOIKVWBIXqCPYI9VpMkk1zKp5//VnhuZFZ7BTlfmOj5FcUYyBEowCQokzlWBQ94UOBmkoi8TZdaIjhtOiiyi2kHCYEw0zkWxRU5f5StLgyw54kvv42mnTLRF6+zx8CX9XcnvIH2QQPnj9xyZmAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 18px;
    padding: 9px 26px 9px 16px;
}

/* Orders Css ends */

.MuiAccordionSummary-expandIconWrapper {
    border: 2px solid #526eff !important;
    height: 40px !important;
    width: 40px !important;
    background-position: center;
    border-radius: 12px;
    color: #526eff !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Mui-expanded {
    margin: 0;
    padding: 0 !important;
}