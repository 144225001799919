a#dropdownUser1 {
    font-size: 15px;
    color: #000;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    border-bottom: 1px solid #c8d4df;
    padding: 0 50px;
}
/* Sidebar css */
.sidebar-col {
    border-right: 1px solid #c8d4df;
}
.sidebar-col .nav-pills .nav-link:hover,
.sidebar-col .nav-pills .nav-link.active {
    background: #f5f7f9;
    border-right: 2px solid #526eff !important;
    border-radius: 0 !important;
}

.sidebar-col .nav-pills .nav-link:hover span.border-menu,
.sidebar-col .nav-pills .nav-link.active span.border-menu {
    background: #5470ff;
}
span.border-menu {
    border: 1px solid #dfe6ec;
    height: 35px;
    width: 35px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}
img.logo {
    height: 55px;
    width: auto;
    margin-right: 15px;
}
p.logo-title {
    margin: 0;
    color: #000;
}
.sidebar-col .nav-pills .nav-link {
    padding: 8px 30px;
    display: flex;
    align-items: center;
}

.sidebar-col .nav-pills .nav-link:hover path,
.sidebar-col .nav-pills .nav-link.active path {
    fill: #fff !important;
}
.sidebar-col ul li {
    border-bottom: 1px solid #c8d4df;
    width: 100%;
}
.sidebar-col ul {
    width: 100%;
}

.logo-col {
    width: 100%;
}

a.nav-link {
    color: rgba(17, 17, 17, 0.8) !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

span.border-menu svg {
    height: 20px !important;
    width: 20px !important;
}

.main-sidebar::-webkit-scrollbar {
    width: 0px;
    background: #f1f1f1;
}



.main-sidecol 
{
    border-right: 1px solid #C8D4DF;
}

.logo-main 
{
    padding: 10px 30px;
    border-bottom: 1px solid #c8d4df;
}

.content-area {
    padding: 18px 50px;
}

